export const simple_demo_points = {
    pickup: [1, 50, 5],
    delivery: [3, 130, 25],
    status: [
        [1, 55, 6],
        [1, 61, 5],
        [1, 68, 5],
        [1, 77, 8],
        [1, 90, 8],
        [1, 100, 7],
        [1, 110, 7],
        [1, 125, 7.5],
        [1, 145, 7.5],
        [1, 165, 8.5],
        [1, 170, 10],
        [1, 172, 17],
        [1, 165, 19],
        [1, 145, 19],
        [1, 130, 21],
        [1, 124, 21],
        [1, 116, 21],
        [1, 97, 19],
        [1, 70, 19],
        [1, 68, 19],
        [3, 68, 19],
        [3, 90, 19],
        [3, 110, 25],
    ]
};

export const unload_demo_points = {
    pickup: [7, 130, 1.5],
    delivery: null,
    status: [
        [7, 135, 2],
        [7, 138, 3.3],
        [7, 146, 4.3],
        [7, 160, 7],
        [7, 175, 6.5],
        [7, 195, 10.5],
        [7, 195, 16],
        [7, 190, 20],
        [7, 180, 20.5],
        [7, 155, 21],
        [7, 145, 25],
        [7, 125, 27],
        [7, 105, 27],
        [5, 72, 23],
        [5, 50, 25],
        [5, 38, 23],
        [5, 20, 24],
        [5, 10, 24],
    ]
};

export const load_demo_points = {
    pickup: null,
    delivery: [7, 9, 20],
    status: [
        [3, 3, 3],
        [3, 15, 3.2],
        [3, 23, 2.8],
        [3, 30, 2.7],
        [3, 40, 3.5],
        [3, 50, 3.5],
        [3, 65, 3.5],
        [3, 80, 3.5],
        [5, 93, 2],
        [5, 100, 1.5],
        [5, 115, 1.8],
        [5, 120, 1.8],
        [7, 138, 3],
        [7, 150, 4.5],
        [7, 165, 6.5],
        [7, 172, 15],
        [7, 155, 20],
        [7, 135, 17],
        [7, 110, 19],
        [7, 90, 20],
        [7, 70, 20],
        [7, 50, 20],
        [7, 35, 20],
        [7, 20, 20],
        [7, 15, 20],
        [7, 12, 20],
    ]
};