import React, { useRef, useState } from 'react';
import { calculateSVGAngle, translateToSVGCoordinates } from './coordinateTranslate';
import { deck_offsets } from '../Decks2';

export function VesselDirectionalPoint({
    position,
    nextPoint,
    prevPoint,
    textColor,
    pointFill = "blue",
    pointStroke = "none",
    size = 3
}) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const pointRef = useRef(null);
    
    const {deckId: deckId1, x: x1, y: y1} = position;
    
    const yOffset = deck_offsets[deckId1];
    const svg_point = translateToSVGCoordinates(position);
    
    const svg_x = svg_point[0];
    const svg_y = svg_point[1];

    let angle = 90;

    // Define triangle points relative to the center:
    // The triangle is defined with an apex at (0, -size*1.5) (stretched to point more distinctly)
    // and base points at (size, size) and (-size, size)
    const trianglePoints = `0,${-size*1.5} ${size},${size} ${-size},${size}`;
    

    if(nextPoint){
        const {deckId: deckId2, x: x2, y: y2} = nextPoint;
        const svg_point2 = translateToSVGCoordinates(nextPoint);
        svg_point2[1] = svg_point2[1] + deck_offsets[deckId2];
        svg_point[1] = svg_point[1] + deck_offsets[deckId1];
        angle = calculateSVGAngle(svg_point, svg_point2);
    } else {
        if(prevPoint){
            const {deckId: deckId2, x: x2, y: y2} = prevPoint;
            const svg_point2 = translateToSVGCoordinates(prevPoint);
            svg_point2[1] = svg_point2[1] + deck_offsets[deckId2];
            svg_point[1] = svg_point[1] + deck_offsets[deckId1];
            angle = calculateSVGAngle(svg_point2, svg_point);
        }
    }

    const handleClick = () => {
        setIsPopupOpen(prev => !prev);
    };

    return (
        <g className="eventGroup"
            >
            <polygon
                transform={`translate(${svg_x} ${svg_y + yOffset}) rotate(${angle})`}
                ref={pointRef}
                fill={pointFill}
                stroke={pointStroke}
                strokeWidth="1"
                points={trianglePoints}
                onClick={handleClick}
            />
        </g>
    );
}
