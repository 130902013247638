import React from "react";
const { DateTime } = require("luxon");

import { Typography } from 'antd';
const { Text } = Typography;

import ProTable from '@ant-design/pro-table';

import TractorEventSubTable from "./TractorEventSubTable";
import {singleTripLink} from "../Helpers";


export default function TractorTable(props){

    const columns = [
        { title: 'Tractor ID', dataIndex: 'tag', key: 'tag', width: 80},
        { title: 'Name', dataIndex: 'name', key: 'name', width: 80},
        { title: 'Deck', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>ID: </Text><Text>{record.deck_id}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.deck_time ? DateTime.fromISO(record.deck_time, {zone: "utc"}).toFormat('dd/MM/yyyy HH:mm:ss') : record.deck_time}</Text>
                </React.Fragment>
        },
        { title: 'Tractor Position', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Latitude: </Text><Text>{record.position_latitude}</Text><br/>
                    <Text strong>Longitude: </Text><Text>{record.position_longitude}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.position_time ? DateTime.fromISO(record.position_time, {zone: "utc"}).toFormat('dd/MM/yyyy HH:mm:ss') : record.position_time}</Text>
                </React.Fragment>
        },
        { title: 'Trailer', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Number: </Text><Text>{record.trailer_number}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.trailer_number_time ? DateTime.fromISO(record.trailer_number_time, {zone: "utc"}).toFormat('dd/MM/yyyy HH:mm:ss') : record.trailer_number_time}</Text>
                </React.Fragment>
        },
        { title: 'Trip', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Active: </Text><Text>{record.trip_active ? "True" : "False"}</Text><br/>
                    <Text strong>ID: </Text><Text>{singleTripLink(record.trip_id)}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.trip_time ? DateTime.fromISO(record.trip_time, {zone: "utc"}).toFormat('dd/MM/yyyy HH:mm:ss') : record.trip_time}</Text>
                </React.Fragment>
        },
        // { title: 'Longitude', dataIndex: 'position_longitude', key: 'position_longitude', render: (val, record) => 
        //     props.trucksLoading[record.tag] ? <Spin spinning /> : val
        // },
        // { title: 'Actions', dataIndex: 'fetch_data', key: 'fetch_data', 
        //     render: (val, record) => 
        //         <React.Fragment>
        //             <Button 
        //                 disabled
        //                 type="primary" 
        //                 onClick={() => props.fetchTruckData(record.tag)} 
        //                 loading={props.trucksLoading[record.tag]}
        //                 icon={<CarOutlined />}> 
        //                     Update
        //             </Button>
        //         </React.Fragment>
        // },
    ];


    return <ProTable
        columns={columns}
        loading={props.loading}
        pagination={{ pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true, size: "small", hideOnSinglePage: false, style:{marginBottom: 0} }}
        bordered={true}
        expandable={{ 
            expandedRowRender: record => {return <TractorEventSubTable tag={record.tag} dataZoomDate={props.dataZoomDate} />},
            rowExpandable: _record => 1 > 0,
        }}
        rowKey="tag"
        dataSource={props.data}
        scroll={{ y: props.scroll }}
        search={false}
        options={{'reload': () => props.fetchData()}}
    />;
}
