import React, { useState, useRef, useEffect } from 'react';
import { decks_config, deck_metadata, deck_offsets } from './Decks2';
import SVG from 'react-inlinesvg';
import './Vessel.css';
import VesselPoint, { vesselPointFactory } from './utils/VesselPoint';
import Deck from './Deck';
import VesselPolyline from './utils/VesselPolyline';
import { VesselDirectionalPoint } from './utils/VesselDirectionalPoint';
import { PopupContent, PopupLine } from './utils/VesselPopup';
import { tripEventColor, tripTypeColor } from '../Helpers';
import { load_demo_points, unload_demo_points, simple_demo_points } from './vessel_demo_points';
import { Button, ConfigProvider } from 'antd';

export default function Vessel_Hollandia({ svgRef, handleLoaded, events,
    viewBox,
    onMouseWheelHandler,
    onMouseDownHandler,
    onMouseMoveHandler,
    onMouseUpHandler,
    onMouseLeaveHandler,
}) {

    //const { status, pickup, delivery } = load_demo_points;
    const [demoPoints, setDemoPoints] = useState(load_demo_points);
    const [deckSlots, setDeckSlots] = useState([]);

    function tripTypeChangeHandler(tripType) {
        // Create a deep copy of decks_config
        let tempSlots = decks_config.map(deck => ({
            ...deck,
            lanes: deck.lanes.map(lane => ({ ...lane }))
        }));

        if (tripType === "LOAD") {
            setDemoPoints(load_demo_points);
            const deck7Index = tempSlots.findIndex(deck => deck.nr === 7);

            if (deck7Index !== -1) {
                // Find the specific lane
                const laneIndex = tempSlots[deck7Index].lanes.findIndex(
                    lane => lane.lane === 4 && lane.number === 1
                );

                if (laneIndex !== -1) {
                    // Create a new lane object with updated status
                    tempSlots[deck7Index].lanes[laneIndex] = {
                        ...tempSlots[deck7Index].lanes[laneIndex],
                        status: "occupied"
                    };
                }
            }
        } else if (tripType === "UNLOAD") {
            setDemoPoints(unload_demo_points);
        } else if (tripType === "SHIFT") {
            setDemoPoints(simple_demo_points);
            const deck3Index = tempSlots.findIndex(deck => deck.nr === 3);

            if (deck3Index !== -1) {
                // Find the specific lane
                const laneIndex = tempSlots[deck3Index].lanes.findIndex(
                    lane => lane.lane === 2 && lane.number === 4
                );

                if (laneIndex !== -1) {
                    // Create a new lane object with updated status
                    tempSlots[deck3Index].lanes[laneIndex] = {
                        ...tempSlots[deck3Index].lanes[laneIndex],
                        status: "occupied"
                    };
                }
            }
        }

        setDeckSlots(tempSlots);
    }

    useEffect(() => {
        tripTypeChangeHandler("LOAD");
    }, []);


    return (
        <React.Fragment>
            <div style={{ zIndex: 998, position: "absolute", top: 4, left: 4, padding: 4 }} >
                <div style={{ display: "flex", flexDirection: "row", columnGap: 16 }}>
                    <div style={{ width: "auto" }}>
                        <div style={{
                            padding: 10,
                            border: '1px solid rgba(255, 255, 255, 0.4)',
                            borderRadius: 8,
                            background: 'rgba(255, 255, 255, 0.8)',
                            backdropFilter: 'blur(5px)',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                        }}>
                            <ul style={{ listStyleType: "none", padding: 0 }}>
                                <li style={{ marginBottom: 8 }}>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                // Seed Token
                                                colorPrimary: tripTypeColor[1],
                                                borderRadius: 2,

                                                // Alias Token
                                                colorBgContainer: '#f6ffed',
                                            },
                                        }}
                                    >
                                        <Button type="primary" onClick={() => tripTypeChangeHandler("LOAD")}>LOAD</Button>
                                    </ConfigProvider>
                                </li>

                                <li style={{ marginBottom: 8 }}>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                // Seed Token
                                                colorPrimary: tripTypeColor[2],
                                                borderRadius: 2,

                                                // Alias Token
                                                colorBgContainer: '#f6ffed',
                                            },
                                        }}
                                    >
                                        <Button type="primary" onClick={() => tripTypeChangeHandler("UNLOAD")}>UNLOAD</Button>
                                    </ConfigProvider>
                                </li>

                                <li style={{ marginBottom: 8 }}>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                // Seed Token
                                                colorPrimary: tripTypeColor[5],
                                                borderRadius: 2,

                                                // Alias Token
                                                colorBgContainer: '#f6ffed',
                                            },
                                        }}
                                    >
                                        <Button type="primary" onClick={() => tripTypeChangeHandler("SHIFT")}>OTHER</Button>
                                    </ConfigProvider>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox={viewBox}
                onWheel={onMouseWheelHandler}
                onMouseDown={onMouseDownHandler}
                onMouseMove={onMouseMoveHandler}
                onMouseUp={onMouseUpHandler}
                onMouseLeave={onMouseLeaveHandler}
            >
                <g ref={svgRef}>


                    {deckSlots.map((value, i) => {
                        return (
                            <g key={deck_metadata[value.nr.toString()]["id"]}>
                                <Deck yOffset={deck_offsets[value.nr]} source={deck_metadata[value.nr.toString()]["source"]}
                                    config={value} deck_id={deck_metadata[value.nr.toString()]["id"]} svgRef={svgRef} onLoad={handleLoaded} />
                            </g>
                        )
                    })}


                    <VesselPolyline positions={demoPoints.status} stroke={tripTypeColor[1]} strokeWidth={1} />

                    {demoPoints.pickup && vesselPointFactory("pickup", { x: demoPoints.pickup[1], y: demoPoints.pickup[2], deckId: demoPoints.pickup[0] },
                        <PopupContent>
                            <PopupLine>Trip id: 1742286354755943</PopupLine>
                            <PopupLine>ACTION: PICKUP</PopupLine>
                            <PopupLine>trip time: ...</PopupLine>
                            <PopupLine>tractor: TUG-119</PopupLine>
                        </PopupContent>)}

                    {demoPoints.delivery && vesselPointFactory("delivery", { x: demoPoints.delivery[1], y: demoPoints.delivery[2], deckId: demoPoints.delivery[0] },
                        <PopupContent>
                            <PopupLine>Trip id: 1742286354755943</PopupLine>
                            <PopupLine>ACTION: DELIVERY</PopupLine>
                            <PopupLine>trip time: wasdasdwasd</PopupLine>
                            <PopupLine>tractor: tug-119</PopupLine>
                        </PopupContent>)}


                    {demoPoints.status.map(([deckId, x, y], index) => {
                        // Skip the last point or calculate a default angle for it
                        const nextPoint = index < demoPoints.status.length - 1 ? demoPoints.status[index + 1] : null;
                        const prevPoint = index > 0 ? demoPoints.status[index - 1] : null;

                        return (
                            <VesselDirectionalPoint
                                key={index}
                                position={{ x, y, deckId }}
                                nextPoint={nextPoint ? { x: nextPoint[1], y: nextPoint[2], deckId: nextPoint[0] } : null}
                                prevPoint={prevPoint ? { x: prevPoint[1], y: prevPoint[2], deckId: prevPoint[0] } : null}
                                pointFill="grey"
                            />
                        );
                    })}

                </g>
            </svg>

        </React.Fragment>

    )
}