export const deck_metadata = {
    8: { "source": "/deck8.svg", "id": "Deck_8" },
    7: { "source": "/deck7.svg", "id": "Deck_7" },
    5: { "source": "/deck5.svg", "id": "Deck_5" },
    3: { "source": "/deck3.svg", "id": "Deck_3" },
    1: { "source": "/deck1.svg", "id": "Deck_1" },
}

import generated_vessel_slots from './generated_vessel_slots.json';

const reversed_vessel_slots = generated_vessel_slots.reverse();

export const decks_config = reversed_vessel_slots;

export const deck_offsets = generated_vessel_slots.reduce((acc, deck, index) => {
    acc[deck.nr] = index * 100;
    return acc;
}, {});