import React, { useState, useEffect } from "react";
const { DateTime } = require("luxon");

import { Typography, Row, Col, Button, Modal, Select, DatePicker, Checkbox } from 'antd';
const { RangePicker } = DatePicker;
import { SettingOutlined } from "@ant-design/icons";
const { Text } = Typography;


export default function DataZoom(props) {

    // Data zoom
    const [dataZoomOpen, setDataZoomOpen] = useState(false)
    const [dataZoomDateTemp, setDataZoomDateTemp] = useState(props.dataZoomDate)
    const [dataZoomTrucksTemp, setDataZoomTrucksTemp] = useState(props.dataZoomTrucks)
    const [dateWithTime, setDateWithTime] = useState(false);

    useEffect(() => {
        setDataZoomDateTemp(props.dataZoomDate)
        setDataZoomTrucksTemp(props.dataZoomTrucks)
    }, [props.dataZoomDate, props.dataZoomTrucks])

    const onClose = () => {
        setDataZoomDateTemp(props.dataZoomDate)
        setDataZoomTrucksTemp(props.dataZoomTrucks)
        setDataZoomOpen(false)
    }

    function handleDateChange(date) {
        if(date == null){
            date = [null, null]
        }
        let dateAfter = date[0] != null ? DateTime.fromHTTP(date[0].toString()) : null;
        let dateBefore = date[1] != null ? DateTime.fromHTTP(date[1].toString()) : null;
        
        dateAfter = dateAfter ? dateAfter.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
        dateBefore = dateBefore ? dateBefore.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;

        setDataZoomDateTemp(
            {
                dateAfter,
                dateBefore
            }
        )
    }

    function handleDateWithTimeCheck(e){
        setDateWithTime(e.target.checked)

    }

    function dataZoomOnSubmit(){
        let { dateAfter, dateBefore } = dataZoomDateTemp;
        
        console.log(dateAfter, dateBefore);
        // Parse dateAfter and dateBefore as ISO dates, if they exist
        dateAfter = dateAfter != null ? DateTime.fromISO(dateAfter) : null;
        dateBefore = dateBefore != null ? DateTime.fromISO(dateBefore) : null;

        
        if (!dateWithTime && dateAfter && dateBefore) {
            // Set dateAfter to the start of the day
            dateAfter = dateAfter.startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
        
            // Set dateBefore to the end of the same day
            dateBefore = dateBefore.endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
        } else {
            // If the dates are not on the same day (or with time is checked), format them as usual
            dateAfter = dateAfter ? dateAfter.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
            dateBefore = dateBefore ? dateBefore.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
        }
        
        // Pass the formatted dates to the applyDataZoom function
        props.applyDataZoom({ dateAfter, dateBefore }, dataZoomTrucksTemp);
        setDataZoomOpen(false);

    }


    return (
        <React.Fragment>
            <Button
                type="primary"
                size="large"
                icon={<SettingOutlined />}
                onClick={() => setDataZoomOpen(true)}
                style={{ marginRight: 4 }}
            >
                Data Zoom
            </Button>
            <Modal
                title="Data Zoom"
                open={dataZoomOpen}
                onOk={onClose}
                onCancel={onClose}
                footer={[
                    <Button key="submit" type="primary" onClick={() => { dataZoomOnSubmit(); }}>
                        Apply settings
                    </Button>
                ]}
                style={{}}
            >
                <Row gutter={4} type="flex">
                    <Col span={24}>
                        <Text strong style={{ paddingRight: 6, paddingLeft: 3 }}>Date range</Text>
                        <Checkbox checked={dateWithTime} onChange={handleDateWithTimeCheck}>
                            Date with time
                        </Checkbox>
                        <RangePicker showTime={dateWithTime}
                            allowEmpty={[true, true]}
                            style={{ width: "100%" }}
                            onChange={(value) => { handleDateChange(value) }} />
                    </Col>
                    <Col span={24}>
                        <Text strong style={{ paddingRight: 6, paddingLeft: 3 }}>Tractors</Text>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            value={dataZoomTrucksTemp}
                            options={props.dataZoomTrucksLookup}
                            onChange={val => { setDataZoomTrucksTemp(val) }}
                            maxTagCount="responsive"
                            placeholder="Select tractors..."
                        />
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    );
}

