import React, { useLayoutEffect, useRef, useState } from 'react';
import { translateToSVGCoordinates } from './coordinateTranslate';
import { deck_offsets } from '../Decks2';
import { PopupContent, PopupLine } from './VesselPopup';

export default function VesselPoint({ position, textColor, pointFill, radius, pointStroke, children }) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [textDimensions, setTextDimensions] = useState({ width: 0, height: 0 });
    const pointRef = useRef(null);
    const textRef = useRef(null);
    const {deckId, x, y} = position;


    const yOffset = deck_offsets[deckId];

    const svg_point = translateToSVGCoordinates({deckId, x, y});
    const svg_x = svg_point[0];
    const svg_y = svg_point[1];

    const handleClick = () => {
        console.log(svg_x, svg_y)
        console.log(pointRef.current.getBoundingClientRect());
        setIsPopupOpen(prev => !prev);
    };

    // runs before the browser repaints the screen
    useLayoutEffect(() => {
        if (isPopupOpen && textRef.current) {
            const bbox = textRef.current.getBBox();
            console.log(bbox);
            setTextDimensions({
                width: bbox.width,
                height: bbox.height
            })
        }
    }, [isPopupOpen]);

    const popupContentChild = React.Children.toArray(children)
        .find(child => child.type === PopupContent);

    /*
    1. get all the children of PopupContent
    2. filter out only the PopupLine components
    3. map over the PopupLine components and get their children (the text part)
    */
    const popupLines = React.Children.toArray(popupContentChild.props.children)
        .filter(child => child.type === PopupLine)
        .map(child => child.props.children);
        

    return (
        <g className="eventGroup"
            transform={`
                translate(${svg_x} ${svg_y + yOffset})`}>
            <circle
                ref={pointRef}
                fill={pointFill ? pointFill : "red"}
                stroke={pointStroke ? pointStroke : "none"}
                r={radius ? radius : 2}
                onClick={handleClick}
            />
            {/* Background click handler to close popup */}
            {isPopupOpen && (
                <rect
                    x={-500}
                    y={-500}
                    width={1000}
                    height={1000}
                    fill="transparent"
                    onClick={() => setIsPopupOpen(false)}
                />
            )}
            {isPopupOpen && (
                <g>
                    {textDimensions.width > 0 && (
                        <rect
                            x={-textDimensions.width / 2 - 2} // Add some padding
                            y={-(radius ? radius : 2) - 8 - textDimensions.height + 3} // Adjust based on text position
                            width={textDimensions.width + 4} // Add some padding
                            height={textDimensions.height + 2} // Add some padding
                            fill="#fff"
                            stroke="#000"
                            strokeWidth={0.1}
                            rx={1} // Rounded corners
                        />
                    )}
                    <text
                        fill='#000'
                        fontSize={3}
                        textAnchor="middle"
                        x={0}
                        y={-(radius ? radius : 2) - textDimensions.height - 1}
                        ref={textRef}>
                        {/* <tspan x="0" dy="0">1742286354755943</tspan>
                        <tspan x="0" dy="4">ACTION: </tspan>
                        <tspan x="0" dy="4">trip time: wasdasdwasd</tspan>
                        <tspan x="0" dy="4">tractor: tug-119</tspan> */}
                        {popupLines.map((line, index) => (
                            <tspan x="0" dy={index === 0 ? "0" : "4"}>
                                {line}
                            </tspan>
                        ))}
                    </text>
                </g>
            )}
        </g>
    );
}

/*
// <VesselPoint
                        // key={index}
                        // deckId={event[1]}
                        // pointFill={tripEventColor["delivery"]}
                        // point={event}
                        // radius={3}/>
*/

export const vesselPointFactory = (type, position, popupContent) => {
    if (type === "pickup") {
        return (
            <VesselPoint
            position={position}
                radius={3}
                pointStroke={"rgb(230, 131, 60)"}
                pointFill={"rgba(230, 131, 60, 0.3)"}
            >
                {popupContent}
            </VesselPoint>
        );
    }
    else if (type === "delivery") {
        return (
            <VesselPoint
            position={position}
                radius={3}
                pointStroke={"rgb(80, 148, 35)"}
                pointFill={"rgba(80, 148, 35, 0.3)"}
            >
                {popupContent}
            </VesselPoint>
        );
    }
    else if (type === "status") {
        return (
            <VesselPoint
            position={position}
                radius={3}
                pointFill={"rgb(255, 255, 255)"}
            >
                {popupContent}
            </VesselPoint>
        );
    }
    return null;
};

