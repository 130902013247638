import React, { useState, useRef, useEffect, useCallback } from 'react';
import Vessel_Hollandia from './Hollandia';
import './Vessel.css';
import { VesselTypes } from '../Helpers';

export default function Vessel({ vesselName }) {
    const [svgCoordinates, setSvgCoordinates] = useState({ x: 0, y: 0 });
    const width = window.innerWidth;
    const height = window.innerHeight;
    const svgRef = useRef(null);

    const [viewBox, setViewBox] = useState({ x: 0, y: 0, w: width, h: height });
    const [scale, setScale] = useState(1);
    const [isPanning, setIsPanning] = useState(false);
    const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    const centerX = width / 2.0;
    const centerY = height / 2.0;

    const centerView = useCallback(() => {
        const { width, height } = svgRef.current.getBBox();
        const w = width / 2.0;
        const h = height / 2.0;
        setSvgCoordinates({ x: centerX - w, y: centerY - h });
    }, [centerX, centerY]);

    const handleLoaded = useCallback(() => {
        centerView();
    }, [centerView]);

    useEffect(() => {
        if (svgRef.current) {
            const initialScale = 2;
            const { x, y } = svgCoordinates;
            const scaledX = initialScale * (x - centerX) + centerX;
            const scaledY = initialScale * (y - centerY) + centerY;
            svgRef.current.setAttribute('transform', `matrix(${initialScale}, 0, 0, ${initialScale}, ${scaledX}, ${scaledY})`);
        }
    }, [svgCoordinates, centerX, centerY]);

    const onMouseWheelHandler = useCallback((e) => {
        const w = viewBox.w;
        const h = viewBox.h;
        const mx = e.nativeEvent.offsetX;
        const my = e.nativeEvent.offsetY;
        const dw = w * Math.sign(e.deltaY * -1) * 0.05;
        const dh = h * Math.sign(e.deltaY * -1) * 0.05;
        const dx = dw * mx / width;
        const dy = dh * my / height;
        const newViewBox = { x: viewBox.x + dx, y: viewBox.y + dy, w: viewBox.w - dw, h: viewBox.h - dh };
        setViewBox(newViewBox);
        setScale(width / newViewBox.w);
    }, [viewBox, width, height]);

    const onMouseDownHandler = useCallback((e) => {
        setIsPanning(true);
        setStartPoint({ x: e.clientX, y: e.clientY });
    }, []);

    const onMouseMoveHandler = useCallback((e) => {
        if (isPanning) {
            const endPoint = { x: e.clientX, y: e.clientY };
            const dx = (startPoint.x - endPoint.x) / scale;
            const dy = (startPoint.y - endPoint.y) / scale;
            setViewBox((prev) => ({
                x: prev.x + dx,
                y: prev.y + dy,
                w: prev.w,
                h: prev.h,
            }));
            setStartPoint(endPoint);
        }
    }, [isPanning, startPoint, scale]);

    const onMouseUpHandler = useCallback(() => {
        setIsPanning(false);
    }, []);

    const onMouseLeaveHandler = useCallback(() => {
        setIsPanning(false);
    }, []);

    const legendItems = [
        { color: "orange", label: "Unknown", "type": "parking_slot" },
        { color: "red", label: "Occupied", "type": "parking_slot" },
        { color: "green", label: "Free", "type": "parking_slot" },
        { color: "blue", label: "Allocated", "type": "parking_slot" },
        { color: "#02df02", label: "Trip load", "type": "trip" },
        { color: "#8c8cf7", label: "Trip unload", "type": "trip" },
        { color: "teal", label: "Trip yard shift", "type": "trip" }
    ];

    const legendDropdown =
        <div style={{
            padding: 10,
            border: '1px solid rgba(255, 255, 255, 0.4)',
            borderRadius: 8,
            background: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(5px)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
        }} className="noSelect">
            <div style={{ marginBottom: 8, fontWeight: 'bold', color: '#333' }}>Legend</div>
            {legendItems.map((item, index) => {
                if (item.type == "parking_slot") {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                            <div style={{
                                width: 22,
                                height: 22,
                                background: item.color,
                                marginRight: 8,
                                border: '1px solid #000'
                            }}></div>
                            <div style={{ color: '#333' }}>{item.label}</div>
                        </div>
                    )
                }
                if (item.type == "trip") {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                            <div style={{
                                width: 22,
                                height: 6,
                                background: item.color,
                                marginRight: 8
                            }}></div>
                            <div style={{ color: '#333' }}>{item.label}</div>
                        </div>
                    )
                }

            })}
        </div>

    const vesselComponent = ((vessel) => {
        switch (vessel) {
            case VesselTypes.HOLLANDIA:
                return <Vessel_Hollandia
                    svgRef={svgRef}
                    handleLoaded={handleLoaded}
                    viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
                    onMouseWheelHandler={onMouseWheelHandler}
                    onMouseDownHandler={onMouseDownHandler}
                    onMouseMoveHandler={onMouseMoveHandler}
                    onMouseUpHandler={onMouseUpHandler}
                    onMouseLeaveHandler={onMouseLeaveHandler} />;
            default:
                return null;
        }
    });

    return (
        <div className="vessel-root">
            <div id="filtersbox" style={{ zIndex: 998, position: "absolute", top: 4, right: 4, padding: 4 }}>
                <div style={{ display: "flex", flexDirection: "row", columnGap: 16 }}>
                    <div style={{ width: 142 }}>
                        {legendDropdown}
                    </div>
                </div>
            </div>


            {vesselComponent(vesselName)}

        </div>
    );
}