// Description: This file contains the functions to translate the coordinates from the deck to the SVG coordinates.

const get_factor = (deck_id) => {
    switch (deck_id) {
        case 1:
            return 2.61;
        case 3:
            return 2.4863;
        case 5:
            return 2.4741;
        case 7:
            return 2.49399;
        case 8:
            return 2.8539;
        default:
            return 1;
    }
}

const get_origin = (deck_id) => {
    switch (deck_id) {
        case 1:
            return [-9.5317, 31.495];
        case 3:
            return [2.99661, 31.47157];
        case 5:
            return [0.000, 31.505];
        case 7:
            return [0.568, 31.505];
        case 8:
            return [-40.661, 31.505];
        default:
            return [0, 0];
    }
}

export function translateToSVGCoordinates(point) {
    const {deckId, x, y} = point;
    const factor = get_factor(deckId);

    const svg_x = (get_origin(deckId)[0] * factor) + (x * factor);
    const svg_y = (get_origin(deckId)[1] * factor) - (y * factor);

    return [svg_x, svg_y];
}

export function calculateSVGAngle(point1, point2) {
    const [x1, y1] = point1;
    const [x2, y2] = point2;

    const deltaX = x2 - x1;
    const deltaY = -(y2 - y1);

    // Get angle in radians and convert to degrees
    let angleDegrees = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    // Since your triangle points upward at angle=0, adjust by 90 degrees
    // This transforms the angle where 0° means pointing upward
    angleDegrees = (angleDegrees * -1) + 90;

    return angleDegrees;
}