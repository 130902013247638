import React, { Children } from 'react';
import { translateToSVGCoordinates } from './coordinateTranslate';
import { deck_offsets } from '../Decks2';

export default function VesselPolyline({ positions, fill = "none", stroke = "red", strokeWidth = 2}) {

    // Validate points_array
    if (!Array.isArray(positions) || !positions.every(point => Array.isArray(point) && point.length === 3)) {
        console.error('Invalid points_array. It should be an array of arrays of size 3 (deck_id, x, y).');
        return null;
    }

    return (
        <React.Fragment>
            <polyline
                points={positions.map(([deckId, x, y]) => {
                    const [svg_x, svg_y] = translateToSVGCoordinates({deckId, x, y});
                    const yOffset = deck_offsets[deckId];
                    return `${svg_x},${svg_y + yOffset}`;
                }).join(' ')}
                fill={fill}
                stroke={stroke}
                strokeWidth={strokeWidth}
            />

        </React.Fragment>
    );
}