import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import enUSIntl from 'antd/lib/locale/en_US';

import { Layout, message, Typography, Row, Col, Spin, Menu, ConfigProvider } from 'antd';
import { CalculatorFilled, CalendarFilled, CarOutlined, GlobalOutlined, TagOutlined, NodeIndexOutlined, LineChartOutlined } from "@ant-design/icons";

import EventTable from "./Events/EventTable";
import TripTable from "./Trips/TripTable";
import TractorTable from "./Tractors/TractorTable";
import TrailerTable from "./Trailers/TrailerTable";
import SingleTrip from "./SingleTrip/SingleTrip";
import Map from "./Map/Map";
import DataZoom from "./DataZoom"
import LogoutButton from "./LogoutButton"
import Terminal from "./Statistics/Terminal";
import Vessel_Hollandia from "./Vessel/Hollandia";
import TripMerge from "./TripMerge/TripMerge";
import Truck from "./Statistics/Truck";
import UnitNumber from "./Statistics/UnitNumber";
import TripFrames from "./TripFrames/TripFrames";
import { DateTime } from "luxon";
import { formatDate, VesselTypes } from "./Helpers";
import AnomalyTripsTable from "./Trips/Anomaly/AnomalyTripsTable";
import Vessel from "./Vessel/Vessel";


const { Header, Content, Sider } = Layout;
const { Title } = Typography;

const appBarHeight = 68;
const sidebarWidth = 140;

const AngeLogo = <img src='./images/AngePurpleSmooth.png' style={{ maxWidth: 50, maxHeight: 50, marginTop: 5 }} />

function useWindowSize() {
    const [height, setHeight] = useState([window.innerHeight - 1, window.outerHeight - 1]);
    useLayoutEffect(() => {
        function updateSize() {
            setHeight([window.innerHeight - 1, window.outerHeight - 1]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return height;
}

function useInitialPath() {
    const [initialPath, setInitialPath] = useState('');
    const location = useLocation();
    // const navigate = useNavigate();
    useEffect(() => {
        // console.log(location)
        let initPath = location.pathname
        if (!initPath.endsWith('/')) initPath = initPath + '/'
        setInitialPath(initPath)
    }, [])
    return initialPath
}

// Custom hook to used to prevent on mount execution with useEffects with dependencies
const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            func();
        } else {
            didMount.current = true;
        }
    }, deps);
};


export default function App() {

    const navigate = useNavigate();
    const location = useLocation();
    // const basePath = useInitialPath();
    const currentLocation = (location.pathname).split('/')[1]

    const [initializing, setInitializing] = useState(true);
    const [singlePageOnly, setSinglePageOnly] = useState(false);
    const [loading, setLoading] = useState({ "tractors": false, "events": false, "trips": false, "trailers": false, "terminal_stats": false });

    // style state - keep track of viewport height
    const [intFrameHeight, intOuterFrameHeight] = useWindowSize();

    const [trucks, setTrucks] = useState([]);
    const [events, setEvents] = useState([]);
    const [trips, setTrips] = useState([]);
    const [tripsTableData, setTripsTableData] = useState([]);
    const [anomalyTrips, setAnomalyTrips] = useState([]);
    const [tripTableFilters, setTripTableFilters] = useState({});
    const [anomalyTableFilters, setAnomalyTableFilters] = useState({});
    const [tripMapFilters, setTripMapFilters] = useState({});
    const [trailers, setTrailers] = useState([]);
    const [verificationTypes, setVerificationTypes] = useState([]);

    const [terminalTableData, setTerminalTableData] = useState([]);

    const [parkingSlots, setParkingSlots] = useState([]);
    const [parkingSlotsConfigurations, setParkingSlotsConfigurations] = useState([])
    const [parkingSlotLatUpdate, setParkingSlotLatUpdate] = useState(null)
    const [mapDateRange, setMapDateRange] = useState({});

    // Data zoom
    const [dataZoomTrucksLookup, setDataZoomTrucksLookup] = useState([])
    const [dataZoomFilters, setDataZoomFilters] = useState({ tractors: [], date: { "dateBefore": null, "dateAfter": null } })

    // Starting the application
    useEffect(async () => {
        if (currentLocation == "trip_frames") {
            setSinglePageOnly(true);
        } else {
            await Promise.all([fetchTrucks(), fetchTrailers(), fetchTrips(), fetchTripsForTable(), fetchParkingSlots(), fetchVerificationType()]).then(e => setInitializing(false));
        }
    }, [])

    // When base path is established, load data
    // useEffect(() => {
    //     fetchTrucks()
    //     navigate(basePath + "tractors")
    // }, [basePath])

    // Watching data zoom filters
    useDidMountEffect(() => {
        fetchTrucks();
        fetchEvents();
        fetchTrailers();
        fetchTrips();
        fetchTripsForTable();
    }, [dataZoomFilters])

    const fetchVerificationType = async () => {
        await axios.get(window.vtrack_config.REACT_APP_API_URL + "/trailers/verification-types")
            .then(response => {
                if (response.status == 200) {

                    setVerificationTypes(response.data);
                }
                else {
                    console.log(response);
                    message.error("Could not load verification types...");
                }
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load verification types...")
            })
    }

    const fetchParkingSlots = async () => {
        let qparams = {};
        if (parkingSlotLatUpdate) {
            const lastUpdate = new Date(parkingSlotLatUpdate).toISOString().slice(0, 19);
            const trimmedTimestamp = lastUpdate.substring(0, 19);
            qparams["last_update"] = trimmedTimestamp;
        }
        await axios.get(window.vtrack_config.REACT_APP_API_URL + "/get-parking-slots", { params: qparams })
            .then(response => {
                if (response.status === 200) {
                    setParkingSlots(response.data.parking_polygons);
                    setParkingSlotsConfigurations(response.data.configuration_points);
                    setParkingSlotLatUpdate(response.data.latest_update);
                } else {
                    console.log(response);
                    message.error("Could not load parking slots...");
                }
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load parking slots...");
            });
    }

    const fetchTrucks = async () => {
        setLoading(currLoading => { return { ...currLoading, "tractors": true } })
        // Handle data zoom params
        let qparams = {}
        if (dataZoomFilters.date.dateAfter) qparams["dateAfter"] = dataZoomFilters.date.dateAfter
        if (dataZoomFilters.date.dateBefore) qparams["dateBefore"] = dataZoomFilters.date.dateBefore
        if (dataZoomFilters.tractors.length > 0) qparams["tags"] = dataZoomFilters.tractors
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trucks', { params: qparams })
            .then(response => {
                if (response.status === 200) {
                    // TODO use a lookup endpoint, for now a trick to generate tractor lookup
                    if (trucks.length === 0) {
                        var tractorList = []
                        response.data.trucks.map(track => tractorList.push({ label: track.name, value: track.tag }))
                        setDataZoomTrucksLookup(tractorList)
                        // setDataZoomTrucksLookup(response.data.trucks.map(track => {{value: track.tag}}))
                    }
                    setTrucks(response.data.trucks)
                } else {
                    console.log(response);
                    message.error("Could not load tractors...")
                }
                setLoading(currLoading => { return { ...currLoading, "tractors": false } })
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load tractors...")
                setLoading(currLoading => { return { ...currLoading, "tractors": false } })
            });
    }

    const fetchEvents = async () => {
        setLoading(currLoading => { return { ...currLoading, "events": true } })
        // Handle data zoom params
        let qparams = {}
        if (dataZoomFilters.date.dateAfter) qparams["dateAfter"] = dataZoomFilters.date.dateAfter
        if (dataZoomFilters.date.dateBefore) qparams["dateBefore"] = dataZoomFilters.date.dateBefore
        if (dataZoomFilters.tractors.length > 0) qparams["tags"] = dataZoomFilters.tractors
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/events', { params: qparams })
            .then(response => {
                if (response.status === 200) {
                    setEvents(response.data.events)
                } else {
                    console.log(response);
                    message.error("Could not load events...")
                }
                setLoading(currLoading => { return { ...currLoading, "events": false } })
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load events...")
                setLoading(currLoading => { return { ...currLoading, "events": false } })
            });
    }

    const fetchTrips = async (page = 1, pageSize = 300, params = {}) => {
        setLoading(currLoading => { return { ...currLoading, "trips": true } })
        // Handle data zoom params
        let qparams = {}
        if (params.sortField && params.sortOrder) {
            qparams["sortField"] = filters.sortField
            qparams["sortOrder"] = filters.sortOrder
        }
        if (params.date && (params.date.dateAfter || params.date.dateBefore)) {
            qparams["dateAfter"] = params.date.dateAfter;
            qparams["dateBefore"] = params.date.dateBefore;
        } else {
            if(!dataZoomFilters.date.dateAfter && !dataZoomFilters.date.dateBefore){
                qparams["dateAfter"] = formatDate(DateTime.now().setZone("utc").minus({hours: 24}));
                qparams["dateBefore"] = formatDate(DateTime.now().setZone("utc"));
            } else {
                let tempDateRange = {dateAfter: null, dateBefore: null};
                if (dataZoomFilters.date.dateAfter) {
                    qparams["dateAfter"] = dataZoomFilters.date.dateAfter;
                    tempDateRange.dateAfter = dataZoomFilters.date.dateAfter;
                }
                if (dataZoomFilters.date.dateBefore) {
                    qparams["dateBefore"] = dataZoomFilters.date.dateBefore;
                    tempDateRange.dateBefore = dataZoomFilters.date.dateBefore;
                }
                setMapDateRange(tempDateRange);
            }
        }
        if (dataZoomFilters.tractors.length > 0) qparams["tags"] = dataZoomFilters.tractors
        if (page) qparams["page"] = page;
        if (pageSize) qparams["pageSize"] = pageSize;
        qparams["includePath"] = true;
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips', { params: qparams })
            .then(response => {
                if (response.status === 200) {
                    setTrips(response.data);
                } else {
                    console.log(response);
                    message.error("Could not load trips...")
                }
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load trips...")
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            });
    }

    const searchTrip = async (page = 1, pageSize = 50, params) => {
        setLoading(currLoading => { return { ...currLoading, "trips": true } });

        await axios.post(window.vtrack_config.REACT_APP_API_URL + '/trips/search', params)
            .then(response => {
                if (response.status === 200) {
                    setTripsTableData(response.data)
                } else {
                    console.log(response);
                    message.error("Could not load trips...")
                }
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load trips...")
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            });
    }

    /*
    Filters usage, all filter values are arrays:
    <column name in database>: [<matching data taype values>]
    */
    const fetchTripsForTable = async (page = 1, pageSize = 50, params = tripTableFilters) => {
        setLoading(currLoading => { return { ...currLoading, "trips": true } });
        if (params) setTripTableFilters(params);
        // Handle data zoom params
        let qparams = {}
        if (params.sortField && params.sortOrder) {
            qparams["sortField"] = params.sortField
            qparams["sortOrder"] = params.sortOrder
        }
        if (params.filters) {
            for (const key in params.filters) {
                if (params.filters[key] && Array.isArray(params.filters[key]) && typeof params.filters[key][0] === 'object' && params.filters[key] !== null) {
                    for (const subKey in params.filters[key][0]) {
                        qparams[`filters[${key}][${subKey}]`] = params.filters[key][0][subKey];
                    }
                } else if (params.filters[key] && Array.isArray(params.filters[key])) {
                    qparams[`filters[${key}]`] = params.filters[key];
                }
            }
        }


        if (dataZoomFilters.date.dateAfter) qparams["dateAfter"] = dataZoomFilters.date.dateAfter
        if (dataZoomFilters.date.dateBefore) qparams["dateBefore"] = dataZoomFilters.date.dateBefore
        if (dataZoomFilters.tractors.length > 0) qparams["tags"] = dataZoomFilters.tractors
        qparams["page"] = page;
        qparams["pageSize"] = pageSize;
        qparams["includePath"] = false;
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips', { params: qparams })
            .then(response => {
                if (response.status === 200) {
                    setTripsTableData(response.data)
                } else {
                    console.log(response);
                    message.error("Could not load trips...")
                }
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load trips...")
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            });
    }

    const fetchAnomalyTrips = async (page = 1, pageSize = 150, params = anomalyTableFilters) => {
        setLoading(currLoading => { return { ...currLoading, "anomaly_trips": true } });
        if (params) setAnomalyTableFilters(params);
        // Handle data zoom params
        let qparams = {}
        if (params.sortField && params.sortOrder) {
            qparams["sortField"] = params.sortField
            qparams["sortOrder"] = params.sortOrder
        }
        // if (params.filters) {
        //     for (const key in params.filters) {
        //         if (params.filters[key] && Array.isArray(params.filters[key]) && typeof params.filters[key][0] === 'object' && params.filters[key] !== null) {
        //             for (const subKey in params.filters[key][0]) {
        //                 qparams[`filters[${key}][${subKey}]`] = params.filters[key][0][subKey];
        //             }
        //         } else if (params.filters[key] && Array.isArray(params.filters[key])) {
        //             qparams[`filters[${key}]`] = params.filters[key];
        //         }
        //     }
        // }

        // Might be dangerous, it will send all the key value pairs if given some object accidentally
        Object.keys(params)
            .filter((key) => key != "filters")
            .forEach((key) => qparams[key] = params[key]);

        //if (dataZoomFilters.tractors.length > 0) qparams["tags"] = dataZoomFilters.tractors
        qparams["page"] = page;
        qparams["pageSize"] = pageSize;
        qparams["includePath"] = false;
        console.log(qparams);

        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips/anomaly', { params: qparams })
            .then(response => {
                if (response.status === 200) {
                    setAnomalyTrips(response.data)
                } else {
                    console.log(response);
                    if(error.response.data.detail) message.error(error.response.data.detail, 8)
                }
                setLoading(currLoading => { return { ...currLoading, "anomaly_trips": false } })
            })
            .catch(error => {
                console.log(error);
                if(error.response.data.detail) message.error(error.response.data.detail, 8);
                setLoading(currLoading => { return { ...currLoading, "anomaly_trips": false } })
            });
    }

    const fetchMergedTrips = async (page = 1, pageSize = 50, params) => {
        setLoading(currLoading => { return { ...currLoading, "trips": true } })
        let qparams = {}
        if (params.date.dateAfter) qparams["dateAfter"] = params.date.dateAfter
        if (params.date.dateBefore) qparams["dateBefore"] = params.date.dateBefore
        if (params.tag) qparams["tag"] = params.tag
        if (params.durationThreshold) qparams["durationThreshold"] = params.durationThreshold
        qparams["page"] = page;
        qparams["pageSize"] = pageSize;
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/merge-trips', { params: qparams })
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data)
                } else {
                    console.log(response);
                    message.error("Could not load trips...")
                }
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load trips...")
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            });
    }

    const fetchMergeTest = async (page = 1, pageSize = 50, params = {}) => {
        setLoading(currLoading => { return { ...currLoading, "trips": true } })
        let qparams = {}
        if (params.tag) qparams["tag"] = params.tag
        qparams["page"] = page;
        qparams["pageSize"] = pageSize;
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/get-merge-trips', { params: qparams })
            .then(response => {
                if (response.status === 200) {
                    setTripsTableData(response.data)
                } else {
                    console.log(response);
                    message.error("Could not load trips...")
                }
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load trips...")
                setLoading(currLoading => { return { ...currLoading, "trips": false } })
            });
    }

    const fetchTrailers = async () => {
        setLoading(currLoading => { return { ...currLoading, "trailers": true } })
        // Handle data zoom params
        let qparams = {}
        if (dataZoomFilters.date.dateAfter) qparams["dateAfter"] = dataZoomFilters.date.dateAfter
        if (dataZoomFilters.date.dateBefore) qparams["dateBefore"] = dataZoomFilters.date.dateBefore
        if (dataZoomFilters.tractors.length > 0) qparams["tags"] = dataZoomFilters.tractors
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trailers', { params: qparams })
            .then(response => {
                if (response.status === 200) {
                    setTrailers(response.data.trailers)
                } else {
                    console.log(response);
                    message.error("Could not load trailers...")
                }
                setLoading(currLoading => { return { ...currLoading, "trailers": false } })
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load trailers...")
                setLoading(currLoading => { return { ...currLoading, "trailers": false } })
            });
    }

    const fetchTripStatistics = async () => {
        setLoading(currLoading => { return { ...currLoading, "terminal_stats": true } })
        await axios.get(window.vtrack_config.REACT_APP_API_URL + "/statistics/terminal")
            .then(response => {
                if (response.status == 200) {
                    setTerminalTableData(response.data);
                } else {
                    console.log(response);
                    message.error("Could not load terminal statistics")
                }
                setLoading(currLoading => { return { ...currLoading, "terminal_stats": false } })
            })
            .catch(error => {
                console.log(error);
                message.error("could not load terminal statistics");
                setLoading(currLoading => { return { ...currLoading, "terminal_stats": false } })
            })
    }

    const updateTripRow = (trip_id, updated) => {

        setTripsTableData(prevState => ({
            ...prevState,
            trips: prevState.trips.map(trip =>
                trip.trip_id === trip_id
                    ? {
                        ...trip,
                        ...updated.trip,
                        approved_trailers: updated.approved_trailers,
                        verification_type_rel: {
                            ...trip.verification_type_rel, // keep other properties unchanged
                            id: updated.trip.verification_type  // update only the id
                        }
                    }
                    : trip
            )
        }));
    }

    const updateTrip = (trip_id, updated) => {
        setTrips(prevState => ({
            //set previous state
            ...prevState,
            //go through each object and find one with the same trip_id
            trips: prevState.trips.map(trip =>
                trip.trip_id === trip_id
                    ? {
                        ...trip,
                        //here happens the updating where the updated object should contain the same field names with new values
                        ...updated.trip,
                        approved_trailers: updated.approved_trailers,
                        verification_type_rel: {
                            ...trip.verification_type_rel, // keep other properties unchanged
                            id: updated.trip.verification_type  // update only the id
                        }
                    }
                    : trip
            )
        }));
    }

    const handleMenuSelection = e => {
        navigate(e.key)
        if (e.key === "tractors" && trucks.length === 0) fetchTrucks()
        if (e.key === 'events' && events.length === 0) fetchEvents()
        if (e.key === 'trips' && trips.length === 0) fetchTrips()
        if (e.key === 'trailers' && trailers.length === 0) fetchTrailers()
        if (e.key === 'map') {
            if (trucks.length === 0) fetchTrucks();
            if (trailers.length === 0) fetchTrailers();
            if (trips.length === 0) fetchTrips();
        }
    }

    const applyDataZoom = (date, tractors) => {
        setDataZoomFilters({ "date": date, "tractors": tractors })
    }

    const menuItems = [
        { label: <a href="#/tractors">Tractors</a>, key: 'tractors', icon: <CarOutlined />, style: { paddingRight: 4 } },
        { label: <a href="#/events">Events</a>, key: 'events', icon: <CalendarFilled />, style: { paddingRight: 4 } },
        { label: <a href="#/trips">Trips</a>, key: 'trips', icon: <CalculatorFilled />, style: { paddingRight: 4 }, children: 
            [
                {key: "anomaly_trips", label: <a href="#/trips/anomaly">Anomalous trips</a>}
            ]
        },
        { label: <a href="#/singletrip">Single Trip</a>, key: 'singletrip', icon: <NodeIndexOutlined />, style: { paddingRight: 4 } },
        { label: <a href="#/trailers">Trailers</a>, key: 'trailers', icon: <TagOutlined />, style: { paddingRight: 4 } },
        { label: <a href="#/map">Map</a>, key: 'map', icon: <GlobalOutlined />, style: { paddingRight: 4 } },
        {
            label: <a href="#/vessel">Vessel</a>, key: 'vessel', icon: <GlobalOutlined />, style: { paddingRight: 4 }, children: [
                { key: "hollandia", label: <a href="#/vessel/hollandia">Hollandia</a> }
            ]
        },
        {
            label: "Statistics", key: "statistics", icon: <LineChartOutlined />, style: { paddingRight: 4 }, children: [
                { key: "terminal", label: <a href="#/statistics/terminal">Terminal</a> },
                { key: "truck", label: <a href="#/statistics/truck">Truck</a> },
                { key: "unit_number", label: <a href="#/statistics/unit_number">Unit number</a> }
            ]
        }
    ]

    return (
        <ConfigProvider locale={enUSIntl}>
            <React.Fragment>
                {initializing && !singlePageOnly
                    ?
                    <span style={{ textAlign: "center", display: "block", paddingTop: 0.3 * intFrameHeight }}>
                        <Spin spinning={initializing} size="large" tip="Initializing VizTrack Cargo Operations..." style={{ marginTop: 0 }}>
                            <img src='./images/AngePurpleSmooth.png' style={{ minWidth: 200, minHeight: 200 }} />
                        </Spin>
                    </span>
                    :
                    singlePageOnly
                        ?
                        <Routes>
                            <Route path="trip_frames">
                                <Route path=":tripId" element={<TripFrames />} />
                            </Route>
                        </Routes>
                        :
                        <Layout style={{ margin: -8 }}>
                            <Header style={{ height: appBarHeight, width: '100%', background: "#fff", borderBottom: "1px solid #ccc", padding: 2 }}>
                                <Row gutter={0} justify="space-between">
                                    <Col>
                                        <Row>
                                            <Col style={{ marginLeft: 4 }}>
                                                {AngeLogo}
                                            </Col>
                                            <Col style={{ marginLeft: 8 }}>
                                                <Title level={2} style={{ color: "#54146b", marginTop: 10, marginBottom: 0 }}>
                                                    VizTrack Cargo Operations
                                                </Title>
                                                {/* <Title level={4} style={{color: "#54146b", marginTop: -6}}>
                                            UI
                                        </Title> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <DataZoom
                                            applyDataZoom={applyDataZoom}
                                            dataZoomTrucksLookup={dataZoomTrucksLookup}
                                            dataZoomDate={dataZoomFilters.date}
                                            dataZoomTrucks={dataZoomFilters.tractors}
                                        />
                                        {window.vtrack_config.USE_API_LOGIN && <LogoutButton />}
                                    </Col>
                                </Row>
                            </Header>
                            <Layout style={{ background: "#222" }}>
                                <Sider theme="light" width={sidebarWidth} style={{ height: 'calc(100vh - 68px)' }}>
                                    <Menu selectedKeys={[currentLocation]}
                                        defaultSelectedKeys={["tractors"]}
                                        onClick={handleMenuSelection}
                                        items={menuItems}
                                    />
                                </Sider>
                                <Layout style={{ borderLeft: "1px solid #ccc", height: 'calc(100vh - 68px)', background: "#fff" }}>
                                    <Content style={{ overflowY: 'auto' }}>
                                        <Routes>
                                            <Route path={"tractors"} element={
                                                <TractorTable
                                                    data={trucks}
                                                    scroll={intFrameHeight - 3.4 * appBarHeight}
                                                    loading={loading.tractors}
                                                    fetchData={fetchTrucks}
                                                    // trucksLoading={trucksLoading}
                                                    dataZoomDate={dataZoomFilters.date}
                                                />}
                                            />
                                            <Route path={"events"} element={
                                                <EventTable
                                                    data={events}
                                                    scroll={intFrameHeight - 3.4 * appBarHeight}
                                                    loading={loading.events}
                                                    fetchData={fetchEvents}
                                                />}
                                            />
                                            <Route path={"trips"} element={
                                                <TripTable
                                                    data={tripsTableData.trips}
                                                    page_size={tripsTableData.page_size}
                                                    count={tripsTableData.count}
                                                    scroll={intFrameHeight - 3.4 * appBarHeight}
                                                    loading={loading.trips}
                                                    fetchData={fetchTripsForTable}
                                                    searchTrip={searchTrip}
                                                    fetchMergedTrips={fetchMergedTrips}
                                                    fetchMergeTest={fetchMergeTest}
                                                    updateTripRow={updateTripRow}
                                                    verificationTypes={verificationTypes}
                                                />}
                                            />
                                            <Route path={"/trips/anomaly"} element={
                                                <AnomalyTripsTable
                                                    data={anomalyTrips.trips}
                                                    page_size={anomalyTrips.page_size}
                                                    count={anomalyTrips.count}
                                                    scroll={intFrameHeight - 3.4 * appBarHeight}
                                                    loading={loading.anomaly_trips}
                                                    fetchData={fetchAnomalyTrips}
                                                    searchTrip={searchTrip}
                                                    updateTripRow={updateTripRow}
                                                    verificationTypes={verificationTypes}
                                                />}
                                            />
                                            <Route path={"singletrip"} element={
                                                <SingleTrip
                                                    trips={trips.trips}
                                                    parkingSlots={parkingSlots}
                                                    intFrameHeight={intFrameHeight}
                                                    appBarHeight={appBarHeight}
                                                />}
                                            >
                                                <Route path=":tripId" element={
                                                    <SingleTrip
                                                        trips={trips.trips}
                                                        parkingSlots={parkingSlots}
                                                        intFrameHeight={intFrameHeight}
                                                        appBarHeight={appBarHeight}
                                                    />}
                                                />
                                            </Route>
                                            <Route path="mergedtrip" element={
                                                <TripMerge
                                                    trips={trips.trips}
                                                    parkingSlots={parkingSlots}
                                                    intFrameHeight={intFrameHeight}
                                                    appBarHeight={appBarHeight}
                                                />
                                            } />
                                            <Route path={"trailers"} element={
                                                <TrailerTable
                                                    data={trailers}
                                                    scroll={intFrameHeight - 3.4 * appBarHeight}
                                                    loading={loading.trailers}
                                                    fetchData={fetchTrailers}
                                                />}
                                            />
                                            <Route path={"map"} element={
                                                <Map
                                                    trailers={trailers}
                                                    tractors={trucks}
                                                    trips={trips}
                                                    parkingSlots={parkingSlots}
                                                    setDateRange={setMapDateRange}
                                                    dateRange={mapDateRange}
                                                    isDataZoom={(dataZoomFilters.date.dateAfter != null && dataZoomFilters.date.dateBefore != null)}
                                                    fetchParkingSlots={fetchParkingSlots}
                                                    parkingSlotsConfigurations={parkingSlotsConfigurations}
                                                    dataZoomTractors={dataZoomFilters.tractors}
                                                    loading={loading.trailers || loading.tractors || loading.trips}
                                                    fetchTractors={fetchTrucks}
                                                    fetchTrailers={fetchTrailers}
                                                    fetchTrips={fetchTrips}
                                                    updateTrip={updateTrip}
                                                />}
                                            />
                                            <Route path={"vessel"} element={<Vessel/>} />
                                            <Route path={"vessel"} >
                                                <Route path="hollandia" element={<Vessel vesselName={VesselTypes.HOLLANDIA} />} />
                                            </Route>
                                            <Route path="statistics">
                                                <Route path="terminal" element={
                                                    <Terminal
                                                        data={terminalTableData}
                                                        fetchData={fetchTripStatistics}
                                                        loading={loading.terminal_stats}
                                                    />} />
                                                <Route path="truck" element={
                                                    <Truck
                                                        dataZoomTrucksLookup={dataZoomTrucksLookup}
                                                        trucks={trucks}
                                                    />}
                                                />
                                                <Route path="unit_number" element={
                                                    <UnitNumber />
                                                }>

                                                </Route>
                                            </Route>

                                            <Route
                                                path="/"
                                                element={<Navigate to={"tractors"} replace />}
                                            />
                                        </Routes>
                                    </Content>
                                </Layout>
                            </Layout>
                        </Layout>
                }
            </React.Fragment>
        </ConfigProvider>
    );
}

