import React, { useEffect, useState } from "react";
const { DateTime } = require("luxon");
import { useNavigate, useLocation } from 'react-router-dom';
import { mergedTripLink, tripTypeColor, TripVerificationIcon } from "../../Helpers";
import { CameraOutlined, ExportOutlined } from '@ant-design/icons';
import { Modal, Checkbox, DatePicker, Input, Divider, TimePicker, message } from 'antd';
const { RangePicker } = DatePicker;
import axios from 'axios';
import dayjs from 'dayjs';

import {
    Typography, Tag,
    Radio,
    Button,
    InputNumber,
    Row,
    Col,
    Slider
} from 'antd';
const { Text, Title, Link } = Typography;

import ProTable from '@ant-design/pro-table';

import TripEventSubTable from "../TripEventSubTable";
import { getTripDuration } from "../../Helpers";
import TripTrailerMoal from "../TripTrailerModal";

const TRIP_DURATION = 1;
const YARD_POSITIONING = 2;
const MINIMUM_THRESHOLD = 3;
const MAXIMUM_THRESHOLD = 4;
const TRAILER_NUMBER = 5;
const NO_TRAILER_NUMBER = 6;
const NO_PICKUP_YARD_ID = 7;
const NO_DISCHARGE_YARD_ID = 8;

const styles = {
    anomalyTripSettings: {
        width: '90%',
        '@media (min-width: 576px)': {
            width: '84%',
        },
        '@media (min-width: 768px)': {
            width: '75%',
        },
        '@media (min-width: 992px)': {
            width: '66%',
        },
        '@media (min-width: 1200px)': {
            width: '50%',
        },
    },
};

export default function AnomalyTripsTable(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [filters, setFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    const [trailerImagesModal, setTrailerImagesModal] = useState(false);
    const [selectedTrip, setSelectedTrip] = useState({});

    const [leftDuration, setLeftDuration] = useState(null);
    const [rightDuration, setRightDuration] = useState(null);

    const [tripIdSearch, setTripIdSearch] = useState(null);

    const [anomalyTrips, setAnomalyTrips] = useState([]);

    const [anomalyFilters, setAnomalyFilters] = useState({});

    const [verificationTypes, setVerificationTypes] = useState({});

    const [editableFields, setEditableFields] = useState({});
    const [editingRowKey, setEditingRowKey] = useState({ trip_id: 0 });

    const [anomalySettingsOpen, setAnomalySettingsOpen] = useState(false);

    const [minimumTripTime, setMinimumTripTime] = useState(dayjs("00:00", "mm:ss").second(30));
    const [maximumTripTime, setMaximumTripTime] = useState(dayjs("00:00", "mm:ss").minute(20));

    const [noTrailerNumber, setNoTrailerNumber] = useState(true);
    const [noPickupSlot, setNoPickupSlot] = useState(true);
    const [noDischargeSlot, setNoDischargeSlot] = useState(true);

    const [dateWithTime, setDateWithTime] = useState(false);
    const [dateRange, setDateRange] = useState({});
    const [dateRangeOriginalValue, setDateRangeOriginalValue] = useState(null);
    const [anomalyMinThreshold, setAnomalyMinThreshold] = useState(0);
    const [anomalyMaxThreshold, setAnomalyMaxThreshold] = useState(0);

    const [optionsState, setOptionsState] = useState(0); // All options are initially enabled

    const isOptionEnabled = (bitPosition) => {
        const bit = 1 << bitPosition;
        return (optionsState & bit) == 0;
    };


    useEffect(() => {
        if (minimumTripTime) {
            setAnomalyMinThreshold(parseFloat((minimumTripTime.get("minutes") + (minimumTripTime.get("seconds") / 60)).toFixed(4)));
        }
    }, [minimumTripTime])

    useEffect(() => {
        if (maximumTripTime) {
            setAnomalyMaxThreshold(parseFloat((maximumTripTime.get("minutes") + (maximumTripTime.get("seconds") / 60)).toFixed(4)));
        }
    }, [maximumTripTime])

    useEffect(() => {
        if (props.data) {
            setAnomalyTrips(props.data)
        }
    }, [props.data])


    useEffect(() => {
        const result = props.verificationTypes.reduce((acc, item) => {
            acc[item.id] = item.name.charAt(0).toUpperCase() + item.name.slice(1);
            return acc;
        }, {});
        setVerificationTypes(result);
    }, [props.verificationTypes])

    function anomalySettingsOnSubmit() {
        let { dateAfter, dateBefore } = dateRange

        dateAfter = dateAfter != null ? DateTime.fromISO(dateAfter) : null;
        dateBefore = dateBefore != null ? DateTime.fromISO(dateBefore) : null;
        if (!dateWithTime && dateAfter && dateBefore) {
            // Set dateAfter to the start of the day
            dateAfter = dateAfter.startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
            // Set dateBefore to the end of the same day
            dateBefore = dateBefore.endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
        } else {
            // If the dates are not on the same day (or with time is checked), format them as usual
            dateAfter = dateAfter ? dateAfter.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
            dateBefore = dateBefore ? dateBefore.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
        }

        let params = {
            optionsState,
            dateAfter: dateAfter,
            dateBefore: dateBefore,
            minDuration: (!isOptionEnabled(MINIMUM_THRESHOLD) || !isOptionEnabled(TRIP_DURATION)) ? null : anomalyMinThreshold,
            maxDuration: (!isOptionEnabled(MAXIMUM_THRESHOLD) || !isOptionEnabled(TRIP_DURATION)) ? null : anomalyMaxThreshold,
            noTrailerNumber: (!isOptionEnabled(NO_TRAILER_NUMBER) || !isOptionEnabled(TRAILER_NUMBER)) ? false : noTrailerNumber,
            noPickupSlot: (!isOptionEnabled(NO_PICKUP_YARD_ID) || !isOptionEnabled(YARD_POSITIONING)) ? false : noPickupSlot,
            noDischargeSlot: (!isOptionEnabled(NO_DISCHARGE_YARD_ID) || !isOptionEnabled(YARD_POSITIONING)) ? false : noDischargeSlot
        }
        setAnomalyFilters(params);
        updateQueryParams(params);

        // fetch trips based on settings
        props.fetchData(undefined, undefined, params);
    }

    //Start editable trip fields

    const handleEditClick = (trip_id, value, field) => {
        setEditingRowKey({ trip_id: trip_id, field: field });
        setEditableFields({
            [field]: value
        });
    };

    const handleCancel = () => {
        setEditingRowKey({ trip_id: 0 }); // cancel edit
    };

    const handleSave = (trip_id) => {


        axios.post(window.vtrack_config.REACT_APP_API_URL + `/trips/${trip_id}/edit`, editableFields)
            .then(response => {
                setEditingRowKey({ trip_id: 0 });
                props.updateTripRow(trip_id, response.data);
            })
            .catch(error => {
                console.error("There was an error updating the trip!", error);
            });

    };

    //END editable trip fields

    function handleDateWithTimeCheck(e) {
        setDateWithTime(e.target.checked)

    }

    function handleTrailerImageModalOpen(tripId, selected) {
        setTrailerImagesModal(true);
        //fetchTripTrailerImages(tripId);

        setSelectedTrip(selected);

    }

    function handleDateChange(date) {
        setDateRangeOriginalValue(date);
        let dateAfter = date[0] != null ? DateTime.fromHTTP(date[0].toString()) : null;
        let dateBefore = date[1] != null ? DateTime.fromHTTP(date[1].toString()) : null;

        dateAfter = dateAfter ? dateAfter.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
        dateBefore = dateBefore ? dateBefore.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;

        setDateRange(
            {
                dateAfter,
                dateBefore
            }
        )
    }

    const onClose = () => {
        setAnomalySettingsOpen(false)
    }

    function handleOptionChange(value, option) {
        if (option == "minimum_duration") {
            setMinimumTripTime(value);
        }
        else if (option == "maximum_duration") {
            setMaximumTripTime(value);
        }
        else if (option == "no_trailer_number") {
            setNoTrailerNumber(value)
        }
        else if (option == "no_pickup_slot") {
            
        }
        else if (option == "no_discharge_slot") {
            
        }
    }

    const tripFilters = {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8, width: 220 }}>
                {/* first filter */}
                <div style={{ marginBottom: 16 }}>
                    <Row
                        type="flex"
                        gutter={10}
                        align="middle"
                        style={{ marginBottom: 16 }}
                    >
                        <Col>
                            <Text>Range: </Text>
                            <InputNumber
                                value={leftDuration}
                                onChange={e => setLeftDuration(e)}
                                style={{ width: 60, marginLeft: 8, marginRight: 8 }}
                            />
                            <Text> - </Text>
                            <InputNumber
                                value={rightDuration}
                                onChange={e => setRightDuration(e)}
                                style={{ width: 60, marginLeft: 8 }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Slider
                            range
                            defaultValue={[leftDuration, rightDuration]}
                            value={[leftDuration, rightDuration]}
                            onChange={e => {
                                setLeftDuration(e[0]);
                                setRightDuration(e[1]);
                            }}
                            style={{ width: '100%' }}
                        />
                    </Row>

                    <Row style={{ marginBottom: 8 }}>
                        <Button
                            block
                            size="small"
                            onClick={() => {
                                // Reset the filter values
                                setLeftDuration(null);
                                setRightDuration(null);
                            }}
                        >
                            Reset
                        </Button>
                    </Row>

                    <Row>
                        <Button
                            type="primary"
                            block
                            size="small"
                            onClick={async () => {
                                setSelectedKeys([{ ...selectedKeys[0], duration_range: { less_than: rightDuration, more_than: leftDuration } }]);
                                confirm();
                            }}
                        >
                            Confirm
                        </Button>
                    </Row>
                </div>

                <Divider style={{ margin: '8px 0' }} />

                {/* second filter */}
                <div style={{ marginBottom: 16 }}>
                    <Row
                        type="flex"
                        gutter={10}
                        align="middle"
                        style={{ marginBottom: 16 }}
                    >
                        <Col>Search by trip id:</Col>
                        <Col>
                            <Input
                                placeholder="Enter trip id..."
                                value={tripIdSearch}
                                onChange={e => {
                                    setTripIdSearch(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Button
                            type="primary"
                            block
                            size="small"
                            onClick={() => {
                                // Apply second filter logic here
                                //props.searchTrip(undefined, undefined, { trip_id: tripIdSearch });
                                setSelectedKeys([{ ...selectedKeys[0], search: { value: tripIdSearch, field: "trip_id" } }]);
                                confirm();
                            }}
                        >
                            Apply Filter
                        </Button>
                    </Row>
                </div>
            </div>
        )


    }

    const pickupIncludeExclude = {
        filters: [
            {
                text: "Include",
                value: true
            },
            {
                text: "Exclude",
                value: false
            }
        ],
        filterMultiple: false
    }
    const deliveryIncludeExclude = {
        filters: [
            {
                text: "Include",
                value: true
            },
            {
                text: "Exclude",
                value: false
            }
        ],
        filterMultiple: false
    }


    const anomaly_types = [
        {
            tag: "short_trip",
            color: "orange"
        },
        {
            tag: "long_trip",
            color: "volcano"
        },
        {
            tag: "without_trailer",
            color: "geekblue"
        },
        {
            tag: "no_yard_slot",
            color: "cyan"
        }
    ]
    const columns = [
        {
            title: 'Anomaly Type',
            dataIndex: 'anomaly_type',
            key: 'anomaly_type',
            width: 140,
            sorter: (a, b) => a.anomaly_type.length - b.anomaly_type.length,
            filters: [
                { text: "SHORT_TRIP", value: "short_trip" },
                { text: "LONG_TRIP", value: "long_trip" },
                { text: "MISSING_TRAILER_ID", value: "without_trailer" },
                { text: "NO_YARD_SLOT", value: "no_yard_slot" },
                { text: "MISSING_PICKUP_SLOT", value: "missing_pickup_slot" },
                { text: "MISSING_DISCHARGE_SLOT", value: "missing_discharge_slot" }
            ],
            onFilter: (value, record) => record.anomaly_type.indexOf(value) === 0,
            render: (anomaly_type) => (
                <>
                    {anomaly_type.map((type) => {
                        let color;
                        switch (type) {
                            case 'short_trip':
                                color = 'orange';
                                break;
                            case 'long_trip':
                                color = 'volcano';
                                break;
                            case 'missing_trailer_id':
                                color = 'geekblue';
                                break;
                            case 'no_yard_slot':
                                color = 'cyan';
                                break;
                            case 'missing_pickup_slot':
                                color = 'purple';
                                break;
                            case 'missing_discharge_slot':
                                color = 'magenta';
                                break;
                            default:
                                color = 'blue';
                        }
                        return <Tag color={color} key={type} style={{ marginBottom: 4 }}>{type.toUpperCase()}</Tag>;
                    })}
                </>
            ),
        },
        {
            title: 'Trip Info', key: 'trip', dataIndex: "trip_duration", ...tripFilters, width: 180, sorter: true, render: (_val, record) =>
                <>
                    <Text strong>Trip ID: </Text>{mergedTripLink(record)}<br />
                    <Text strong>Tractor ID: </Text><Text>{record.truck.name}</Text><br />
                    <Text strong>Trip duration: </Text> {getTripDuration(record)}
                </>
        },
        {
            title: 'Created', key: 'created_message_time', dataIndex: 'created_message_time', width: 150, sorter: true, render: val =>
                <>
                    UTC: {!!val ? DateTime.fromISO(val, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : val}<br />
                    Local: {!!val ? DateTime.fromISO(val).toFormat('dd/MM/yyyy HH:mm:ss') : val}

                </>

        },
        {
            title: 'Updated', key: 'updated_message_time', dataIndex: 'updated_message_time', width: 150, sorter: true, render: val =>
                <>
                    UTC: {!!val ? DateTime.fromISO(val, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : val}<br />
                    Local: {!!val ? DateTime.fromISO(val).toFormat('dd/MM/yyyy HH:mm:ss') : val}

                </>

        },
        {
            title: 'Status', key: 'status', width: 110, sorter: true, filters: [
                { text: "UNLOAD", value: 1 },
                { text: "LOAD", value: 2 },
                { text: "YARD SHIFT", value: 3 },
                { text: "VESSEL PICKUP", value: 4 },
                { text: "YARD PICKUP", value: 5 },
                { text: "NONE", value: null }
            ],
            onFilter: (value, record) => record.trip_type === value,
            render: (_val, record) => {
                let trip_type_name = record.trip_type_rel ? record.trip_type_rel.name : null
                switch (record.trip_type) {
                    case 1:
                        return <Tag color={tripTypeColor[1]}>{trip_type_name ? trip_type_name : "UNLOAD"}</Tag>;
                    case 2:
                        return <Tag color={tripTypeColor[2]}>{trip_type_name ? trip_type_name : "LOAD"}</Tag>;
                    case 3:
                        return <Tag color={tripTypeColor[3]}>{trip_type_name ? trip_type_name : "YARD SHIFT"}</Tag>;
                    case 4:
                        return <Tag color={tripTypeColor[4]}>{trip_type_name ? trip_type_name : "VESSEL PICKUP"}</Tag>;
                    case 5:
                        return <Tag color={tripTypeColor[5]}>{trip_type_name ? trip_type_name : "YARD PICKUP"}</Tag>;
                    default:
                        return <Tag color={tripTypeColor[0]}>{trip_type_name ? trip_type_name : "EMPTY"}</Tag>;
                }
            }

        },
        {
            title: 'Pickup', key: 'pickup', dataIndex: 'pickup_time', ...pickupIncludeExclude, width: 200, sorter: true,
            render: (_val, record) =>
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Text strong>Time: </Text>
                            <Text>{!!record.pickup_time ? DateTime.fromISO(record.pickup_time, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : record.pickup_time}</Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Yard location: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_yard_id") ? (
                                <Input value={editableFields.pickup_yard_id} onChange={(e) => setEditableFields({ ...editableFields, pickup_yard_id: e.target.value })} />
                            ) : (
                                <Text>{record.pickup_yard_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_yard_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => handleEditClick(record.trip_id, record.pickup_yard_id, 'pickup_yard_id')}>Edit</Text>
                            </div>
                        )}

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Deck location: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_deck_id") ? (
                                <Input value={editableFields.pickup_deck_id} onChange={(e) => setEditableFields({ ...editableFields, pickup_deck_id: e.target.value })} />
                            ) : (
                                <Text>{record.pickup_deck_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_deck_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: "pointer" }} className="edit-button"
                                    onClick={() => handleEditClick(record.trip_id, record.pickup_deck_id, "pickup_deck_id")}
                                >Edit</Text>
                            </div>
                        )}

                    </div>
                </React.Fragment>
        },
        {
            title: 'Discharge', key: 'discharge', dataIndex: 'discharge_time', width: 200, ...deliveryIncludeExclude, sorter: true,
            render: (_val, record) =>
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Text strong>Time: </Text>
                            <Text>{!!record.discharge_time ? DateTime.fromISO(record.discharge_time, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : record.discharge_time}</Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Yard location:</Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_yard_id") ? (
                                <Input value={editableFields.discharge_yard_id} onChange={(e) => setEditableFields({ ...editableFields, discharge_yard_id: e.target.value })} />
                            ) : (
                                <Text>{record.discharge_yard_id} N/A</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_yard_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => handleEditClick(record.trip_id, record.discharge_yard_id, 'discharge_yard_id')}>Edit</Text>
                            </div>
                        )}
                    </div>

                    <Text strong>Vessel:</Text> Unknown

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 16 }} className="editable-row">
                        <div>
                            <Text strong>Deck ID/loc: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_deck_id") ? (
                                <Input value={editableFields.discharge_deck_id} onChange={(e) => setEditableFields({ ...editableFields, discharge_deck_id: e.target.value })} />
                            ) : (
                                <Text>{record.discharge_deck_id} Unknown</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_deck_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: "pointer" }} onClick={() => handleEditClick(record.trip_id, record.discharge_deck_id, "discharge_deck_id")}>Edit</Text>
                            </div>
                        )}
                    </div>
                </React.Fragment>
        },
        {
            title: 'Trailer', key: 'trailer', dataIndex: 'trailer_number_time', filters: props.verificationTypes.map(item => ({
                text: item.name,
                value: item.id
            })), width: 200,
            sorter: true, render: (_val, record) =>
                <React.Fragment>
                    <Text strong>Number: </Text><Text>{(record.user_verified != null && record.user_verified != "") ? record.user_verified : (record.approved_trailers && record.approved_trailers.length > 0) ? record.approved_trailers[0].trailer_number :
                        record.trailer_number
                    }</Text><br />
                    <Text strong>Time: </Text><Text>{!!record.trailer_number_time ? DateTime.fromISO(record.trailer_number_time, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : record.trailer_number_time}</Text>
                    <br />
                    <Link href={`#/trip_frames/${record.trip_id}`} target="_blank">
                        View images
                    </Link>
                    <br/>
                    {(record.trailer_number != null && record.has_images) && (
                        <Row align="middle" justify="space-between">
                            <Col>
                                <Row gutter={8}>
                                    <Col>
                                        <Text>


                                            {/* Verification type check and inline switch case */}

                                            {record["verification_type_rel"] ? (
                                                <>
                                                    {TripVerificationIcon(record["verification_type_rel"]["id"])} {verificationTypes[record["verification_type_rel"]["id"]]}
                                                </>
                                            ) : (
                                                " Unverified"
                                            )}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Button type="primary"
                                    size="small"
                                    icon={<CameraOutlined />}
                                    onClick={() => { handleTrailerImageModalOpen(record.trip_id, record) }}
                                    style={{ marginLeft: 0, marginTop: 8 }}>View OCR</Button>
                            </Col>
                        </Row>
                    )}
                </React.Fragment>
        },
    ];


    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
    
        // Fetch data only when the page is changed
        if (pagination.current !== currentPage) {
            props.fetchData(pagination.current, undefined, anomalyFilters);
        }
    };
    
    const updateQueryParams = (filters) => {
        const queryParams = new URLSearchParams(location.search);
        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                queryParams.set(key, filters[key]);
            } else {
                queryParams.delete(key);
            }
        });
        navigate({ search: queryParams.toString() });
    };
    
    const loadFiltersFromQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        const loadedFilters = {};
        queryParams.forEach((value, key) => {
            loadedFilters[key] = value;
        });
    
        // If no query parameters are given, return and load the page without filters
        if (!loadedFilters.dateAfter && !loadedFilters.dateBefore && !loadedFilters.optionsState) {
            return;
        }
    
        setAnomalyFilters(loadedFilters);
    
        // Check if dateAfter and dateBefore are valid dates using Luxon
        const dateAfter = DateTime.fromISO(loadedFilters.dateAfter);
        const dateBefore = DateTime.fromISO(loadedFilters.dateBefore);
    
        if (dateAfter.isValid && dateBefore.isValid && loadedFilters.optionsState) {
            setDateRange({
                dateAfter: dateAfter.toFormat("yyyy-MM-dd'T'HH:mm:ss"),
                dateBefore: dateBefore.toFormat("yyyy-MM-dd'T'HH:mm:ss")
            });
            setDateRangeOriginalValue([dayjs(dateAfter.toJSDate()), dayjs(dateBefore.toJSDate())]);
            setOptionsState(loadedFilters.optionsState);
        } else {
            let errText = "Could not load options. ";
            if (!loadedFilters.optionsState) {
                message.error(errText + "Option state not provided");
            } else {
                message.error(errText + "Could not parse dateAfter and dateBefore.");
            }
            return;
        }
    
        // fetch trips based on settings
        props.fetchData(undefined, undefined, loadedFilters);
    };
    
    useEffect(() => {
        loadFiltersFromQueryParams();
    }, []);
    
    const ANOMALY_TRIP_SETTINGS = <AnomalyTripSettings handleOptionChange={handleOptionChange}
        maximumTripTime={maximumTripTime}
        minimumTripTime={minimumTripTime}
        dateWithTime={dateWithTime} handleDateChange={handleDateChange}
        handleDateWithTimeCheck={handleDateWithTimeCheck}
        onSubmit={anomalySettingsOnSubmit}
        dateRange={dateRangeOriginalValue}
        setOptionsState={setOptionsState} optionsState={optionsState} />

    return (
        <>
            <style>{`
            .edit-button {
                    visibility: hidden;
                }
                .edit-button > span {
                    color: blue;
                }
                .editable-row:hover .edit-button {
                    visibility: visible;
                }
                .ant-table-body {
                    max-height: calc(100vh - 285px) !important;
                }
                @media (min-width: 576px) {
                    .anomaly-trip-settings-modal {
                        width: 85% !important;
                    }
                }
                @media (min-width: 768px) {
                    .anomaly-trip-settings-modal {
                        width: 70% !important;
                    }
                }
                @media (min-width: 992px) {
                    .anomaly-trip-settings-modal {
                        width: 60% !important;
                    }
                }
                @media (min-width: 1200px) {
                    .anomaly-trip-settings-modal {
                        width: 50% !important;
                    }
                }
            `}</style>




            <Modal
                title="Anomalous trip settings"
                open={anomalySettingsOpen}
                onCancel={onClose}
                footer={[]}
                className="anomaly-trip-settings-modal"
            >
                <Divider />


                {ANOMALY_TRIP_SETTINGS}
            </Modal>
            <TripTrailerMoal
                initTrailerImagesModal={trailerImagesModal}
                setInitTrailerImagesModal={setTrailerImagesModal}
                selectedTrip={selectedTrip}
                updateTrip={props.updateTripRow}
            />

            {anomalyTrips.length > 0 && (
                <React.Fragment>
                    <div style={{ marginLeft: 32, marginTop: 16, display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <Button
                                type="primary"
                                size="large"
                                icon={<ExportOutlined />}
                                onClick={() => setAnomalySettingsOpen(true)}
                            >
                                Open fetch parameters
                            </Button>
                        </div>
                    </div>

                    <ProTable
                        columns={columns}
                        loading={props.loading}
                        pagination={{
                            pageSize: props.page_size, current: currentPage, showSizeChanger: false, size: "small", hideOnSinglePage: false, style: { marginBottom: 0 }, total: props.count
                        }}

                        bordered={true}
                        expandable={{
                            expandedRowRender: record => { return <TripEventSubTable tripId={record.trip_id} /> },
                            rowExpandable: () => 1 > 0,
                        }}
                        rowKey="trip_id"
                        dataSource={anomalyTrips}
                        scroll={{ y: props.scroll }}
                        search={false}
                        options={{ 'reload': () => { props.fetchData() } }}
                        onChange={handleTableChange}
                    />
                </React.Fragment>
            )}

            {((anomalyTrips && anomalyTrips.length == 0) || !anomalyTrips) && (
                <Row justify="center">
                    <Col xxl={12} xl={16} lg={18} md={18} sm={22}>
                        {ANOMALY_TRIP_SETTINGS}
                    </Col>
                </Row>

            )}

        </>

    )

}


function AnomalyTripSettings({ dateWithTime, dateRange, handleDateChange, handleDateWithTimeCheck, onSubmit, maximumTripTime, minimumTripTime, handleOptionChange,
    setOptionsState, optionsState
}) {

    const enableOption = (bitPosition) => {
        const bit = 1 << bitPosition;
        setOptionsState((prevState) => prevState | bit);
    };
    const disableOption = (bitPosition) => {
        const bit = 1 << bitPosition;
        setOptionsState((prevState) => prevState & ~bit);
    };
    const isOptionEnabled = (bitPosition) => {
        const bit = 1 << bitPosition;
        return (optionsState & bit) == 0;
    };
    const setOption = (bitPosition, isEnabled) => {
        const bit = 1 << bitPosition;
        setOptionsState((prevState) =>
            isEnabled ? (prevState | bit) : (prevState & ~bit)
        );
    };


    const toggleOption = (bit) => {
        setOptionsState((prevState) => prevState ^ bit);
    };

    const duration_format = "mm:ss";

    return (
        <div style={{ padding: 32 }}>
            <style>{`
                .optionContainer {
                    padding: 16px;
                    border: '1px solid #d9d9d9';
                    border-radius: 8px;
                    margin-bottom: 16px;
                }
            `}</style>
            <Title>Anomalous Trips</Title>

            <Title level={4}>Date Range</Title>
            <Checkbox checked={dateWithTime} onChange={handleDateWithTimeCheck}>
                Include Time
            </Checkbox>
            <RangePicker
                showTime={dateWithTime}
                value={dateRange}
                allowEmpty={[true, true]}
                style={{ width: "100%", marginTop: 8 }}
                onChange={(value) => { handleDateChange(value); }}

            />


            <Divider />

            <AnomalyOptionContainer isOptionEnabled={isOptionEnabled} setOption={setOption} optionName={"Trip Duration"} optionId={TRIP_DURATION}>

                <Title level={5} style={{ marginTop: 16 }}>Trip Duration</Title>
                <div style={{ marginBottom: 16 }}>
                    <Text strong>Minimum Threshold</Text>
                    <Row align="middle" style={{ marginTop: 8 }}>
                        <Checkbox onChange={(e) => { setOption(MINIMUM_THRESHOLD, !e.target.checked); }} checked={isOptionEnabled(MINIMUM_THRESHOLD)}><span style={{ marginLeft: 8 }}>Short trip threshold:</span></Checkbox>

                        <TimePicker
                            min={0}
                            secondStep={5}
                            value={minimumTripTime}
                            format={duration_format}
                            style={{
                                marginLeft: 8,
                                ...(isOptionEnabled(MINIMUM_THRESHOLD) ? {} : { pointerEvents: "none", opacity: 0.4 })
                            }}
                            onChange={e => { handleOptionChange(e, "minimum_duration"); }}
                        />
                    </Row>
                </div>

                <div>
                    <Text strong>Maximum Threshold</Text>
                    <Row align="middle" style={{ marginTop: 8 }}>
                        <Checkbox onChange={(e) => { setOption(MAXIMUM_THRESHOLD, !e.target.checked); }} checked={isOptionEnabled(MAXIMUM_THRESHOLD)}><span style={{ marginLeft: 8 }}>Long trip threshold:</span></Checkbox>

                        <TimePicker
                            min={0}
                            secondStep={5}
                            value={maximumTripTime}
                            format={duration_format}
                            style={{
                                marginLeft: 8,
                                ...(isOptionEnabled(MAXIMUM_THRESHOLD) ? {} : { pointerEvents: "none", opacity: 0.4 })
                            }}
                            onChange={e => { handleOptionChange(e, "maximum_duration"); }}
                        />
                    </Row>
                </div>

            </AnomalyOptionContainer>

            <Divider />

            <AnomalyOptionContainer isOptionEnabled={isOptionEnabled} setOption={setOption} optionName={"Trailer Number"} optionId={TRAILER_NUMBER}>

                <Title level={5}>Trailer number</Title>

                <Checkbox onChange={(e) => { handleOptionChange(e.target.checked, "no_trailer_number"); setOption(NO_TRAILER_NUMBER, !e.target.checked); }} checked={isOptionEnabled(NO_TRAILER_NUMBER)}><span style={{ marginLeft: 8 }}>Trip without trailer number</span></Checkbox>


            </AnomalyOptionContainer>

            <Divider />

            <AnomalyOptionContainer isOptionEnabled={isOptionEnabled} setOption={setOption} optionName={"Yard Positioning"} optionId={YARD_POSITIONING}>

                <Title level={5}>Yard Positioning</Title>
                <ul style={{ paddingLeft: 16 }}>

                    <Checkbox onChange={(e) => { handleOptionChange(e.target.checked, "no_pickup_slot"); setOption(NO_PICKUP_YARD_ID, !e.target.checked); }} checked={isOptionEnabled(NO_PICKUP_YARD_ID)}>
                        <span style={{ marginLeft: 8 }}>Trip without pickup yard id</span>
                    </Checkbox>
                    <Checkbox onChange={(e) => { handleOptionChange(e.target.checked, "no_discharge_slot"); setOption(NO_DISCHARGE_YARD_ID, !e.target.checked); }} checked={isOptionEnabled(NO_DISCHARGE_YARD_ID)}>
                        <span style={{ marginLeft: 8 }}>Trip without discharge yard id</span>
                    </Checkbox>

                </ul>

            </AnomalyOptionContainer>



            <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={() => { onSubmit(); }}>
                    Fetch Data
                </Button>
            </div>
        </div>

    )
}

function AnomalyOptionContainer({ children, isOptionEnabled, setOption, optionName, optionId }) {

    return (
        <React.Fragment>
            <style>{`
                .optionContainer {
                    padding: 16px;
                    border: 1px solid #d9d9d9;
                    border-radius: 8px;
                    margin-bottom: 16px;
                }
                .customCheckbox {
                    transform: scale(1.5);
                    margin-right: 8px;
                }
                .checkboxLabel {
                    font-weight: bold;
                    color: #1890ff;
                }
            `}</style>

            <div className="optionContainer">
                <Checkbox
                    className="customCheckbox"
                    onChange={(e) => { setOption(optionId, e.target.checked); }}
                    checked={!isOptionEnabled(optionId)}
                >
                    <span className="checkboxLabel">
                        {isOptionEnabled(optionId) ? "Disable" : "Enable"} {optionName}
                    </span>
                </Checkbox>
                <div style={isOptionEnabled(optionId) ? {} : { pointerEvents: "none", opacity: 0.4 }}>
                    {children}
                </div>
            </div>
        </React.Fragment>
    )
}