import { useState, useRef, useEffect } from 'react';
import SVG from 'react-inlinesvg';

export default function Deck({source, yOffset, config, onLoad, deck_id}) {
    const [svgSource, setSvgSource] = useState(null);
    const [yCoord, setYCoord] = useState(0);
    const [svgWidth, setSvgWidth] = useState(null);
    const svgRef = useRef(null);
    const [deckSlots, setDeckSlots] = useState([]);

    useEffect(() => {
        if (source) {
            setSvgSource(source);
        }
    }, [source]);
    
    useEffect(() => {
        setYCoord(yOffset);
    }, [yOffset]);

    // This useEffect handles the initial SVG loading
    function handleLoaded() {
        onLoad();
        const deckElement = svgRef.current.querySelector(`#${deck_id}`);
        if (deckElement) {
            setSvgWidth(deckElement.getAttribute("width"));
        }
        // We don't need to set up slots here anymore
    }

    // Add this new useEffect to update slots whenever config changes
    useEffect(() => {
        if (!svgRef.current) return;
        
        const lanes = config.lanes;
        const styles = config.styles || {};
        
        if (lanes) {
            let slots = [];

            for (let i = 0; i < lanes.length; i++) {
                const lane = lanes[i];
                // Debug logging for specific slot
                if(config.nr === 7 && lane.lane === 4 && lane.number === 1) {
                    console.log("Updating slot status:", lane.status);
                }
                
                let { width, height, x, y } = lane;
                const status = lane.status || "free";

                slots.push(
                    <rect 
                        className={`trailer_slot slot_${status}`} 
                        key={`${deck_id}-${i+1}`} 
                        transform={`translate(${x + 1} ${y + 1})`} 
                        width={width - 2} 
                        height={height - 2} 
                    />
                );
            }
            setDeckSlots(<g style={styles}>{slots}</g>);
        }
    }, [config, deck_id, svgRef.current]); // Add dependencies

    return (
        <g transform={`translate(0,${yCoord})`} ref={svgRef}>
            <text 
                fill='white' 
                x={svgWidth} 
                y={70} 
                style={{
                    pointerEvents: "none",   
                    userSelect: 'none', 
                    WebkitUserSelect: 'none', 
                    MozUserSelect: 'none', 
                    msUserSelect: 'none'
                }}
            >
                {config.name}
            </text>
            <SVG src={svgSource} onLoad={handleLoaded} />
            {deckSlots}
        </g>
    );
}