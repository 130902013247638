import { DatePicker} from 'antd';

const { DateTime } = require("luxon");
const { RangePicker } = DatePicker;


export default function LimitDateRangePicker({handleDateChange}) {

    const disabledDate = (current, { from, type }) => {
        if (from) {
            const minDate = from.add(-29, 'days');
            const maxDate = from.add(29, 'days');
            switch (type) {
                case 'year':
                    return current.year() < minDate.year() || current.year() > maxDate.year();
                case 'month':
                    return (
                        getYearMonth(current) < getYearMonth(minDate) ||
                        getYearMonth(current) > getYearMonth(maxDate)
                    );
                default:
                    return Math.abs(current.diff(from, 'days')) >= 30;
            }
        }
        return false;
    };

    return (
        <RangePicker
            allowEmpty={[false, false]}
            style={{ marginLeft: 10 }}
            onChange={(value) => { handleDateChange(value) }}
            disabledDate={disabledDate}
        />
    )
}